<template lang="pug">
  .container-fluid.wrapper
    #rocky-list
      h5.mb-3 国内の岩場一覧
      #rocky-list-table
        b-table.table(small thead-class="#table-header" borderless striped hover="" :items="items" :fields="fields")
          template(v-slot:cell(name)="data")
            .div-for-router(@click="callRockyInfo(data)")
              b-button.link-button(size="sm" variant="link" @click="callRockyInfo(data)") {{ data.value }}
          template(v-slot:cell(address)="data")
            .div-for-router(@click="callRockyInfo(data)") {{ data.value }}
      div.my-3 {{resultMessage}}
</template>

<script>
import axios from 'axios'

export default {
  // データオブジェクト
  data() {
    return {
      resultMessage:'',
      items : [],
      fields: [
        {
          key: 'name',
          label: '岩場名',
          sortable: true,
          thStyle: { backgroundColor: '#171D32',color: 'white'},
          formatter: 'getRouteChild'
        },
        {
          key: 'address',
          label: '所在地',
          sortable: true,
          thStyle: { backgroundColor: '#171D32',color: 'white'}
        }
      ],
    }
  },
  // インスタンス作成後
  created() {
    this.setRcokyListData()
  },
  // メソッド
  methods: {
    callRockyInfo(data){
      this.$router.push('/rockyInfo/' + data.item.rockyId )
    },
    async setRcokyListData() {
      let jsonUrl = process.env.VUE_APP_MATERIAL_URL + '/allRockyList.json'
      await axios
        .get(jsonUrl)
        .then(response => {
          this.items = response.data
        })
        .catch(err => {
          this.resultMessage = '情報の取得に失敗しました'
          this.items = []
        })

      //データが0件の場合
      if(this.items.length == 0){
        //ここでデータが取得できない場合は、データ不正
        await this.asyncDialog('システムエラー：表示情報を取得できませんでした')
      　return
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
//コンテナ群
#app
  .wrapper
    margin-top -25px
    margin-bottom -25px

//テーブル
.table
  margin-bottom 1rem
  color #000
  background-color #F5F5F6
  .btn-link 
    text-align left
.link-button
  font-size 0.9rem
  padding 0rem

//IEの場合、リンクに外枠がついてしまう現象の対策
@media all and (-ms-high-contrast: none)
  #app
    .btn
      box-shadow 0 0

//共通設定を上書き
#app
  .btn
    box-shadow unset
  .btn:hover
    box-shadow unset

#rocky-list
  margin 1.5rem 0
  padding 1rem 0

@media (min-width 920px)
  #rocky-list
    margin 2rem auto
    width 80%
</style>